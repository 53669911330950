<template>
  <router-view></router-view>
</template>

<script>
import './assets/css/nucleo/css/nucleo.css'
import './assets/css/nucleo/css/nucleo.css'
import './assets/css/font-awesome/all.css'
import './assets/css/style.css'
import './assets/css/fonts/ibm-plex-sans.css'
import './assets/sass/argon.scss'
import './assets/scss/custom.scss'

export default {
  name: 'App',
  components: {}
}
</script>