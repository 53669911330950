import { createStore } from "vuex";
const store = createStore({
  state() {
    return {
      user: {},
      title: "",
      states: [],
      isHideMenu: false,
    };
  },
  mutations: {
    setUser(state, payload) {
      state.user = payload;
    },
    setTitle(state, payload) {
      state.title = payload;
    },
    setStates(state, payload) {
      state.states = payload;
    },
    setIsHideMenu(state, payload) {
      state.isHideMenu = payload;
    },
  },
  getters: {
    getUser(state) {
      return state.user;
    },
    getTitle(state) {
      return state.title;
    },
    getStates(state) {
      return state.states;
    },
    getIsHideMenu(state) {
      return state.isHideMenu;
    },
  },
  actions: {},
});

export default store;
